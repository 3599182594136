/* eslint-disable */
import {
  activateDocumentType,
  activateStorageGate,
  activateStorageItem,
  createDocumentType,
  createStorageBuilding,
  createStorageDirection,
  createStorageGate,
  createStorageItem,
  createStorageRestrictionType,
  createStorageRestrictionTypeCar,
  createStorageRestrictionTypeCarExtended,
  createStorageRestrictionTypeScheduleRange,
  createStorageWorkTime,
  deactivateDocumentType,
  deactivateStorageBuilding,
  deactivateStorageGate,
  deactivateStorageItem,
  deleteStorageBuilding,
  deleteStorageRestrictionTypeCarExtended,
  getDocumentTypes,
  getStorageBreakTime,
  getStorageDirection,
  getStorageGate,
  getStorageItem,
  getStorageList,
  getStorageRestrictionType,
  getStorageRestrictionTypeCar,
  getStorageRestrictionTypeCarExtended,
  getStorageRestrictionTypeScheduleRange,
  getStorageWorkTime,
  getStorageInboundDirections,
  getStorageOutboundDirections,
  getStorageSlotList,
  getStorageBuildings,
  getDirectionLastSlot,
  getLastOldScheduleDate,
  updateDocumentType,
  updateStorageBuilding,
  updateStorageDirection,
  updateStorageGate,
  updateStorageItem,
  updateStorageRestrictionType,
  updateStorageRestrictionTypeCar,
  updateStorageRestrictionTypeCarExtended,
  updateStorageRestrictionTypeScheduleRange,
  updateStorageSlotSizeByDirection,
  updateStorageWorkTime,
  updateWorkTimeName,
  getStorageSettings,
  updateStorageSettings,
} from '@/methods/storage';
import { ElNotification } from 'element-plus';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  item: {},
  currentStorageItemId: parseInt(localStorage.getItem('currentStorageItemId')) || null,
  restrictionType: [],
  restrictionTypeCar: [],
  restrictionTypeCarExtended: [],
  restrictionTypeScheduleRange: [],
  gate: [],
  direction: [],
  directionLastSlot: {},
  inboundDirections: [],
  outboundDirections: [],
  workTime: [],
  settings: null,
  breakTime: [],
  documentTypes: [],
  slotSize: null,
  slotList: [],
  buildings: [],
  lastOldScheduleDate: null,
  dataIsUpdated: {
    item: false,
    direction: false,
    breakTime: false,
    workTime: false,
    slotSize: false,
    storageSettings: false,
    documentTypes: false,
    restrictionType: false,
    restrictionTypeCar: false,
    restrictionTypeCarExtended: false,
    restrictionTypeScheduleRange: false,
    gate: false,
  },
  isLoading: false,
};

const getters = {
  getStorageList(state) {
    return state.list;
  },
  getStorageItem(state) {
    return state.item;
  },
  getCurrentStorageItemId(state) {
    return state.currentStorageItemId;
  },
  getStorageRestrictionType(state) {
    return state.restrictionType;
  },
  getStorageRestrictionTypeCar(state) {
    return state.restrictionTypeCar;
  },
  getStorageRestrictionTypeCarExtended(state) {
    return state.restrictionTypeCarExtended;
  },
  getStorageRestrictionTypeScheduleRange(state) {
    return state.restrictionTypeScheduleRange;
  },
  getStorageGate(state) {
    return state.gate;
  },
  getStorageDirection(state) {
    return state.direction;
  },
  getStorageWorkTime(state) {
    return state.workTime;
  },
  getStorageBreakTime(state) {
    return state.breakTime;
  },
  getDocumentTypes(state) {
    return state.documentTypes;
  },
};

const mutations = {
  UPDATE_STORAGE_LIST(state, data) {
    data = data.map((storage) => {
      if (storage.buildings.length) {
        storage.buildings = storage.buildings.filter((building) => building.active);
      }

      return storage;
    });

    state.list = data;
  },
  UPDATE_STORAGE_ITEM(state, data) {
    if (data.buildings.length) {
      data.buildings = data.buildings.filter((building) => building.active);
    }

    state.item = data;
  },
  UPDATE_CURRENT_STORAGE_ITEM(state, id) {
    state.item = state.list.filter((item) => item.id === parseInt(id))[0];
  },
  UPDATE_CURRENT_STORAGE_ITEM_ID(state, id) {
    state.currentStorageItemId = id;
    localStorage.setItem('currentStorageItemId', id);
  },
  UPDATE_STORAGE_RESTRICTION_TYPE(state, data) {
    state.restrictionType = data.map((item) => {
      let directionIds = [];

      item.direction.forEach((item) => {
        directionIds.push(item.id);
      });

      item['typeId'] = item.type.id;
      item['typeCode'] = item.type.code;
      item['directionIds'] = directionIds;

      return item;
    });
    state.restrictionType = data;
  },
  UPDATE_STORAGE_RESTRICTION_TYPE_CAR(state, data) {
    state.restrictionTypeCar = data;
  },
  UPDATE_STORAGE_RESTRICTION_TYPE_CAR_EXTENDED(state, { restrictionId, data }) {
    state.restrictionTypeCarExtended[restrictionId] = data;
  },
  UPDATE_STORAGE_RESTRICTION_TYPE_SCHEDULE_RANGE(state, data) {
    state.restrictionTypeScheduleRange = data;
  },
  UPDATE_STORAGE_GATE(state, data) {
    let gateList = [];

    data.forEach((building) => {
      building.gates.map((gateItem) => {
        gateItem['buildingName'] = building.name;
        gateItem['buildingId'] = building.id;
        gateList.push(gateItem);

        return gateItem;
      });
    });

    state.gate = gateList;
  },
  UPDATE_STORAGE_WORK_TIME(state, data) {
    state.workTime = data;
  },
  UPDATE_STORAGE_SETTINGS(state, data) {
    state.settings = data;
  },
  UPDATE_STORAGE_SLOT_LIST(state, data) {
    state.slotList = data;
  },
  UPDATE_STORAGE_BUILDINGS(state, data) {
    state.buildings = data;
  },
  UPDATE_STORAGE_LAST_OLD_SCHEDULE_DATE(state, data) {
    state.lastOldScheduleDate = data?.date ?? null;
  },
  UPDATE_STORAGE_BREAK_TIME(state, data) {
    state.breakTime = data;
  },
  UPDATE_STORAGE_DIRECTION(state, data) {
    state.direction = data;
  },
  UPDATE_DIRECTION_LAST_SLOT(state, data) {
    state.directionLastSlot = data;
  },
  UPDATE_STORAGE_INBOUND_DIRECTIONS(state, data) {
    state.inboundDirections = data;
  },
  UPDATE_STORAGE_OUTBOUND_DIRECTIONS(state, data) {
    state.outboundDirections = data;
  },
  ACTIVATE_STORAGE_ITEM(state, id) {
    state.list = state.list.map((item) => {
      if (item.id === id) {
        item.active = true;
      }

      return item;
    });
  },
  DEACTIVATE_STORAGE_ITEM(state, id) {
    state.list = state.list.map((item) => {
      if (item.id === id) {
        item.active = false;
      }

      return item;
    });
  },
  ACTIVATE_STORAGE_GATE(state, id) {
    state.gate = state.gate.map((item) => {
      if (item.id === id) {
        item.active = true;
      }

      return item;
    });
  },
  DEACTIVATE_STORAGE_GATE(state, id) {
    state.gate = state.gate.map((item) => {
      if (item.id === id) {
        item.active = false;
      }

      return item;
    });
  },
  UPDATE_DOCUMENT_TYPES(state, data) {
    state.documentTypes = data;
  },
  ACTIVATE_DOCUMENT_TYPE(state, id) {
    state.documentTypes = state.documentTypes.map((item) => {
      if (item.id === id) {
        item.active = true;
      }

      return item;
    });
  },
  DEACTIVATE_DOCUMENT_TYPE(state, id) {
    state.documentTypes = state.documentTypes.map((item) => {
      if (item.id === id) {
        item.active = false;
      }

      return item;
    });
  },
  STORAGE_ITEM_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.item = data;
  },
  STORAGE_DIRECTION_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.direction = data;
  },
  STORAGE_BREAK_TIME_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.breakTime = data;
  },
  STORAGE_WORK_TIME_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.workTime = data;
  },
  STORAGE_SLOT_SIZE_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.slotSize = data;
  },
  STORAGE_SETTINGS_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.storageSettings = data;
  },
  STORAGE_DOCUMENT_TYPES_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.documentTypes = data;
  },
  STORAGE_RESTRICTION_TYPE_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.restrictionType = data;
  },
  STORAGE_RESTRICTION_TYPE_CAR_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.restrictionTypeCar = data;
  },
  STORAGE_RESTRICTION_TYPE_CAR_EXTENDED_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.restrictionTypeCarExtended = data;
  },
  STORAGE_RESTRICTION_TYPE_SCHEDULE_RANGE_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.restrictionTypeScheduleRange = data;
  },
  STORAGE_GATE_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.gate = data;
  },
  UPDATE_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  getStorageList({ commit, state }, params) {
    getStorageList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_LIST', response.data.data);
          commit('privilege/UPDATE_SETTINGS_STORAGE_PRIVILEGE', response.data.privilege, { root: true });

          if (state.currentStorageItemId === null && response.data.data.length) {
            commit('UPDATE_CURRENT_STORAGE_ITEM_ID', response.data.data[0].id);
            commit('UPDATE_STORAGE_ITEM', response.data.data[0]);
          } else if (state.currentStorageItemId !== null && response.data.data.length) {
            const currentItem = response.data.data.filter(
              (item) => item.id === parseInt(state.currentStorageItemId)
            )[0];
            commit('UPDATE_STORAGE_ITEM', currentItem);
          }
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_STORAGE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getStorageItem({ commit, state }, params) {
    getStorageItem(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_STORAGE_ITEM', response.data.data);
        commit('STORAGE_ITEM_DATA_IS_UPDATED', true);
      }
    });
  },

  getStorageBreakTime({ commit, state }, params) {
    getStorageBreakTime(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_BREAK_TIME', response.data.data);
          commit('privilege/UPDATE_SETTINGS_BREAK_TIME_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_BREAK_TIME_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getStorageDirection({ commit, state }, params) {
    getStorageDirection(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_DIRECTION', response.data.data);
          commit('privilege/UPDATE_SETTINGS_DIRECTION_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_DIRECTION_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getStorageInboundDirections({ commit, state }, params) {
    getStorageInboundDirections(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_STORAGE_INBOUND_DIRECTIONS', response.data.data);
      }
    });
  },

  getStorageOutboundDirections({ commit, state }, params) {
    getStorageOutboundDirections(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_STORAGE_OUTBOUND_DIRECTIONS', response.data.data);
      }
    });
  },

  getStorageGate({ commit, state }, params) {
    getStorageGate(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_GATE', response.data.data);
          commit('privilege/UPDATE_SETTINGS_GATE_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_GATE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getStorageSettings({ commit, state }, params) {
    getStorageSettings(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_SETTINGS', response.data.data);
          commit('privilege/UPDATE_SETTINGS_STORAGE_SETTINGS_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_STORAGE_SETTINGS_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getStorageSlotList({ commit, state }, params) {
    getStorageSlotList(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_STORAGE_SLOT_LIST', response.data.data);
      }
    });
  },

  getStorageBuildings({ commit, state }, params) {
    getStorageBuildings(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_STORAGE_BUILDINGS', response.data.data);
      }
    });
  },

  getStorageRestrictionType({ commit, state }, params) {
    getStorageRestrictionType(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_RESTRICTION_TYPE', response.data.data);
          commit('privilege/UPDATE_SETTINGS_RESTRICTION_TYPE_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_RESTRICTION_TYPE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getStorageRestrictionTypeCar({ commit, state }, params) {
    getStorageRestrictionTypeCar(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_RESTRICTION_TYPE_CAR', response.data.data);
          commit('privilege/UPDATE_SETTINGS_RESTRICTION_CAR_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_RESTRICTION_CAR_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getStorageRestrictionTypeCarExtended({ commit, state }, { restrictionId }) {
    getStorageRestrictionTypeCarExtended(restrictionId)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_RESTRICTION_TYPE_CAR_EXTENDED', {
            restrictionId: restrictionId,
            data: response.data.data,
          });
          commit('privilege/UPDATE_SETTINGS_RESTRICTION_CAR_EXTENDED_PRIVILEGE', response.data.privilege, {
            root: true,
          });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_RESTRICTION_CAR_EXTENDED_PRIVILEGE', getDenyPrivilegeObject(), {
          root: true,
        });
      });
  },

  getStorageRestrictionTypeScheduleRange({ commit, state }, params) {
    getStorageRestrictionTypeScheduleRange(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_RESTRICTION_TYPE_SCHEDULE_RANGE', response.data.data);
          commit('privilege/UPDATE_SETTINGS_RESTRICTION_RANGE_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_RESTRICTION_RANGE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getStorageWorkTime({ commit, state }, params) {
    getStorageWorkTime(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_STORAGE_WORK_TIME', response.data.data);
          commit('privilege/UPDATE_SETTINGS_WORK_TIME_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_WORK_TIME_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getLastOldScheduleDate({ commit, state }, params) {
    getLastOldScheduleDate(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_STORAGE_LAST_OLD_SCHEDULE_DATE', response.data.data);
      }
    });
  },

  getDirectionLastSlot({ commit, state }, params) {
    getDirectionLastSlot(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_DIRECTION_LAST_SLOT', response.data.data);
      }
    });
  },

  createStorageItem({ commit, dispatch, state }, params) {
    commit('UPDATE_LOADING', true);
    createStorageItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('STORAGE_ITEM_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  createStorageDirection({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    createStorageDirection(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_DIRECTION_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  createStorageGate({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    createStorageGate(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_GATE_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  createStorageRestrictionType({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    createStorageRestrictionType(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  createStorageRestrictionTypeCar({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    createStorageRestrictionTypeCar(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_CAR_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  createStorageRestrictionTypeCarExtended({ commit, dispatch, state }, { restrictionId, data }) {
    commit('UPDATE_LOADING', true);
    createStorageRestrictionTypeCarExtended(restrictionId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_CAR_EXTENDED_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  createStorageRestrictionTypeScheduleRange({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    createStorageRestrictionTypeScheduleRange(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_SCHEDULE_RANGE_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  createStorageWorkTime({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    createStorageWorkTime(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_WORK_TIME_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  createStorageBuilding({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    createStorageBuilding(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageItem({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_ITEM_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageDirection({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageDirection(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_DIRECTION_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageWorkTime({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageWorkTime(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_WORK_TIME_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateWorkTimeName({ commit, dispatch, state }, params) {
    commit('UPDATE_LOADING', true);
    updateWorkTimeName(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_WORK_TIME_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageRestrictionType({ commit, dispatch, state }, params) {
    commit('UPDATE_LOADING', true);
    updateStorageRestrictionType(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageRestrictionTypeCar({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageRestrictionTypeCar(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_CAR_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageRestrictionTypeCarExtended({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageRestrictionTypeCarExtended(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_CAR_EXTENDED_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageRestrictionTypeScheduleRange({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageRestrictionTypeScheduleRange(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_SCHEDULE_RANGE_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageGate({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageGate(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_GATE_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageSlotSizeByDirection({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    updateStorageSlotSizeByDirection(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_SLOT_SIZE_DATA_IS_UPDATED', true);
          window.location.reload();
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageSettings({ commit, dispatch, state }, { storageId, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageSettings(storageId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_SETTINGS_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  updateStorageBuilding({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_LOADING', true);
    updateStorageBuilding(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
        }
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },

  deleteStorageBuilding({ commit, dispatch, state }, params) {
    deleteStorageBuilding(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deactivateStorageBuilding({ commit, state }, params) {
    deactivateStorageBuilding(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  activateStorageItem({ commit, state }, params) {
    activateStorageItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('ACTIVATE_STORAGE_ITEM', params);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deactivateStorageItem({ commit, state }, params) {
    deactivateStorageItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('DEACTIVATE_STORAGE_ITEM', params);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  activateStorageGate({ commit, state }, params) {
    activateStorageGate(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('ACTIVATE_STORAGE_GATE', params);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deactivateStorageGate({ commit, state }, params) {
    deactivateStorageGate(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('DEACTIVATE_STORAGE_GATE', params);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deleteStorageRestrictionTypeCarExtended({ commit, state }, params) {
    deleteStorageRestrictionTypeCarExtended(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_RESTRICTION_TYPE_CAR_EXTENDED_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  getDocumentTypes({ commit, state }, params) {
    getDocumentTypes(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_DOCUMENT_TYPES', response.data.data);
          commit('privilege/UPDATE_SETTINGS_DOCUMENT_TYPE_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_DOCUMENT_TYPE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  createDocumentType({ commit, dispatch, state }, { data }) {
    createDocumentType(data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_DOCUMENT_TYPES_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateDocumentType({ commit, dispatch, state }, { id, data }) {
    updateDocumentType(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('STORAGE_DOCUMENT_TYPES_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  activateDocumentType({ commit, state }, params) {
    activateDocumentType(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('ACTIVATE_DOCUMENT_TYPE', params);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deactivateDocumentType({ commit, state }, params) {
    deactivateDocumentType(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('DEACTIVATE_DOCUMENT_TYPE', params);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
