import { getEventsList } from '@/methods/calendar';
import { ElNotification } from 'element-plus';
import {
  getDenyPrivilegeObject,
  getErrorMessage,
  formatDate,
  formatTimeFromDate,
} from '@/compositions/helpers/helpers';
import { computed } from 'vue';
import store from '@/store';

const user = computed(() => store.state.auth.user);

const state = {
  eventList: [],
  currentDate: '',
  isLoading: false,
};

const getters = {
  getEventsList(state) {
    return state.eventList;
  },
  getCurrentDate(state) {
    return state.currentDate;
  },
  getPartnerData(state, getters, rootState, rootGetters) {
    return rootGetters.getUser;
  },
};

const mutations = {
  UPDATE_EVENTS_LIST(state, data) {
    state.eventList = data;
  },
  UPDATE_CURRENT_DATE(state, data) {
    return (state.currentDate = data);
  },
  UPDATE_IS_LOADING(state, data) {
    state.isLoading = data;
  },
};

const actions = {
  getEventsList({ commit, state, getters, rootGetters }, { params, directionTypeId }) {
    commit('UPDATE_IS_LOADING', true);
    let partner = rootGetters['auth/getUser'].isPartner;
    getEventsList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const days = Object.values(response.data.data.slots);
          const sizeSlots = Object.values(response.data.data.slotSize.slotSizeDirectionCollection);

          const slotsTimeStamp = sizeSlots.map((elem) => {
            return {
              startTime: new Date(elem.startAt + 'T00:00:00').valueOf(),
              finishDate: new Date(elem.endAt + 'T00:00:00').valueOf(),
              size: elem.size,
            };
          });

          const events = days.map((elem) => {
            let statusText;
            let statusClass;
            let counter;
            let flag = false;
            let dividedRestrictions = [];
            let dividedRestrictionsResult = '';

            if (
              (
                directionTypeId === 2 ||
                (directionTypeId !== 2 &&
                  (user.value.isPartner ||
                    user.value.workGroup === 'g_GateManagement_ManagerLocal' ||
                    user.value.workGroup === 'g_GateManagement_ManagerMP_B2B')
                )
              ) && elem?.restrictionByCooperationType
            ) {
              for (const [key, value] of Object.entries(elem?.restrictionByCooperationType)) {
                dividedRestrictions.push(`${key}: ${value}`);
              }

              dividedRestrictionsResult = `<div class="vuecal__event-title-popover">${dividedRestrictions.join(
                '<br>'
              )}</div>`;
            }

            if (directionTypeId === 2) {
              counter = `${elem.freeCount ?? '0'}/${elem.freeCapacityCount ?? '0'}`;
            } else {
              counter = `${elem.freeCount ?? '-'}/${elem.freeCapacityCount ?? '-'}`;
            }

            if (!elem.availability || elem.freeCount <= 0) {
              statusText = 'Недоступно';
              statusClass = 'vuecal__event-title-busy';
            } else {
              statusText = 'Свободно';
              statusClass = 'vuecal__event-title-free';
            }

            const reviewedDate = new Date(elem.startAt).valueOf();
            const dayInMilliseconds = 86400000;

            slotsTimeStamp.forEach((elem) => {
              if (
                reviewedDate >= elem.startTime &&
                (reviewedDate <= elem.finishDate + dayInMilliseconds || !elem.finishDate) &&
                elem.size === 60
              ) {
                flag = true;
              }
            });

            if (flag) {
              return {
                start: `${formatDate(elem.startAt)} ${formatTimeFromDate(elem.startAt)}`,
                end:
                  new Date(elem.startAt).toLocaleString('ru', {
                    minute: 'numeric',
                  }) === '30'
                    ? ''
                    : `${formatDate(elem.endAt)} ${formatTimeFromDate(elem.endAt)}`,
                title: `${formatTimeFromDate(elem.startAt)}, ${statusText} ${
                  !partner 
                    ? counter ?? '' 
                    : statusText === 'Недоступно' 
                      ? '' 
                      : directionTypeId === 2 
                        ? '' 
                        : elem.freeCapacityCount ?? ''
                } ${dividedRestrictionsResult}`,
                class:
                  new Date(elem.startAt).toLocaleString('ru', {
                    minute: 'numeric',
                  }) === '30'
                    ? ''
                    : statusClass,
                id: elem.id,
              };
            } else {
              return {
                start: `${formatDate(elem.startAt)} ${formatTimeFromDate(elem.startAt)}`,
                end: `${formatDate(elem.endAt)} ${formatTimeFromDate(elem.endAt)}`,
                title: `${formatTimeFromDate(elem.startAt)}, ${statusText} ${
                  !partner 
                    ? counter ?? '' 
                    : statusText === 'Недоступно' 
                      ? '' 
                      : directionTypeId === 2 
                        ? '' 
                        : elem.freeCapacityCount ?? ''
                } ${dividedRestrictionsResult}`,
                class: statusClass,
                id: elem.id,
              };
            }
          });
          const sorted = events.sort((a, b) => new Date(a.start).valueOf() - new Date(b.start).valueOf());
          commit('UPDATE_EVENTS_LIST', sorted);
          commit('privilege/UPDATE_CALENDAR_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .then(() => commit('UPDATE_IS_LOADING', false))
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('privilege/UPDATE_CALENDAR_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
        commit('UPDATE_IS_LOADING', false);
      });
  },
  setCurrentDate({ commit, state }, params) {
    commit('UPDATE_CURRENT_DATE', params);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
